
import moment from 'moment';
import Datatable from "@/components/datatable/Datatable.vue";
import { defineComponent, reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import {useStore} from 'vuex';
import { ContentLoader } from 'vue-content-loader';
import DeleteConcessionaria from "@/views/crud/concessionaria/components/DeleteConcessionaria.vue";
import VisualizarConcessionaria from "@/views/crud/concessionaria/components/VisualizarConcessionaria.vue";
import { indexPaginacao } from "@/services/Concessionaria";
import useEmitter from '@/composables/Emmiter';
import {Actions} from "@/store/enums/StoreEnums";


export default defineComponent({
  name: "cadastros-concessionaria-list",
  components: {
    	DeleteConcessionaria,
      VisualizarConcessionaria,
      Datatable,
      ContentLoader
  },

  setup() {
   const data:any = reactive({
        info: {
          current_page: 1,
          data: [],
          first_page_url: "",
          from: 1,
          last_page: 7,
          last_page_url: "",
          links: [],
          next_page_url: "",
          path: "",
          per_page: 50,
          prev_page_url: null,
          to: 0,
          total: 0
        },
        acampaatualizacao: 0,
        cnpj: 0,
        codAtria: 0,
        codConcessionaria: 0,
        logomarca: 0,
        marca: 0,
        nomeFantasia: 0.
			});

    const store = useStore();
    let page = ref(1);
    const concessionaria:any = ref([]); ;
    const router = useRouter();
    const contagempagina =  20
    const contagemArray = 30
    let animate = ref(true);
    let databruta = concessionaria.acampaatualizacao;
    const dadosConcessionaria:any = ref([]);
    const auxDadosConcessionaria:any = ref([]);
    const novoAray2:any = ref([]);
    const fastFilter:any = ref("");
    const codconcessionaria:any = ref({});
    const dadosconcessionaria2 = ref({});
    const codMarcaselect:any = ref({})
    const filtroConsultor:any = ref({})
    const filtroCodatria:any = ref({})
    const dadosconcessionariaEspecifica = ref({});
    const dadosconcessionariaEspecifica2 = ref({});
    let postData: any = ref({});  
    const emitter = useEmitter();
    const codConcessionaria = ref(0);
    const isMobile = ref(false);
    const concessionariaStorage:any = ref(0)
    const semConcessonario = ref()
    

    onMounted(() => {   
      semConcessonario.value = true   
      listaConcessionarias( postData.value); 
      let userAgent = navigator.userAgent || navigator.vendor;
      if(userAgent.match( /Android/i) || userAgent.match(/iPhone/i)) {
        isMobile.value = true;
      } else {
        isMobile.value = false;
      }
    });

    emitter.on("filtrar-crud-concessionaria", async data => {
      animate.value = true;
      codConcessionaria.value = data.codConcessionaria;
      postData.value = {
        codConcessionariaSelected: data.filtrosServicos.codConcessionariaSelected, 
        codAtria: data.filtrosServicos.codAtria,
        situacaoServico: data.filtrosServicos.situacaoServico,
        dadosGrupo: data.filtrosServicos.dadosGrupo
     
      };
      await listaConcessionarias( postData.value);
      animate.value = false;
    });

    function formatarData(databruta) {
      if (databruta) {                                                                                  
        return moment(String(databruta)).format('DD/MM/YYYY HH:mm')
      }
    }

    function chamarConcessionariaEspecifica(concessionaria){
        dadosconcessionariaEspecifica.value = concessionaria
    }

    function chamarConcessionariaEspecificaCompleto(concessionaria){
        dadosconcessionariaEspecifica2.value = concessionaria
    }

    function goToParams(routeName, concessionariaRef){
				router.push(
					{
						name: routeName,
						params: {
							codConcessionaria: concessionariaRef.codConcessionaria, 
              byPush: "true"     
						}      
					}   
				);

        // salva a concessionaria no storage no momento da edição, exibindo a concessionaria selecionada no hrader
         store.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, concessionariaRef.codConcessionaria);
		}; 

    const delayTimer = ref();

    function filterData(){
      clearTimeout(delayTimer.value);
      delayTimer.value = setTimeout(function() {
        trataInfo(1, true);
      }, 300);
    }


    async function listaConcessionarias(postData){
      animate.value = true;
      concessionariaStorage.value = await localStorage.getItem('filtroConcessionaria'); // pega a concessionaria salvo no localStorage

			const response = await indexPaginacao(postData);
  
      if(concessionariaStorage.value){

        semConcessonario.value = false        
        auxDadosConcessionaria.value = [...response];
        let novoArray = auxDadosConcessionaria.value.filter(function(obj) { return obj.codConcessionaria == concessionariaStorage.value; });
        
        novoAray2.value = novoArray;
             
      }else{

        semConcessonario.value = true
        auxDadosConcessionaria.value = response    
      }
      trataInfo(1);
      animate.value = false;   
    }

    function trataInfo(page = 1, filter = false ){
      let baseDadosConcessionaria = [];
      if(filter){
        console.log(filter);
        baseDadosConcessionaria = auxDadosConcessionaria.value.filter(concessionaria => 
          concessionaria.nomeFantasia.toLowerCase().includes(fastFilter.value.toLowerCase()) || 
          concessionaria.codAtria.replace("/", "").includes(fastFilter.value.replace("/", "")) 
        );
      } else {
        baseDadosConcessionaria = auxDadosConcessionaria.value;
      }

      data.info.total = baseDadosConcessionaria.length;
      data.info.last_page = Math.ceil((baseDadosConcessionaria.length / data.info.per_page)) ?? 0;
      data.info.current_page = page;
      const start = data.info.per_page * (page - 1);
      const end =  data.info.per_page * (page);
      if(data.info.last_page == page){
        data.info.to = baseDadosConcessionaria.length;
      } else if(end > baseDadosConcessionaria.length){
        data.info.to = baseDadosConcessionaria.length;
      } else {
        data.info.to = end;
      }
      
      data.info.data = baseDadosConcessionaria.slice(start, end);

      if(concessionariaStorage.value){
        data.info.data.unshift(novoAray2.value[0])
      }

      let novaArr = data.info.data.filter(function(obj, i) {
          return data.info.data.indexOf(obj) === i;
      });

      data.info.data = novaArr ;
    }
    
    function goTo(name){
      router.push(
        {
          name,
          params: {
            byPush: "true"
          }      
        }
      )
		};

    return {
      concessionaria,
      goTo,
      listaConcessionarias,
      data,
      animate,
      page,
      contagempagina,
      contagemArray,
      dadosConcessionaria,
      dadosconcessionaria2,
      chamarConcessionariaEspecifica,
      dadosconcessionariaEspecifica,
      dadosconcessionariaEspecifica2,
      chamarConcessionariaEspecificaCompleto,
      goToParams,
      formatarData,
      trataInfo,
      codconcessionaria,
      codMarcaselect,
      filtroConsultor,
      filtroCodatria,
      fastFilter,
      filterData,
      isMobile,
      novoAray2,
      semConcessonario
    };
  },
});
