
import moment from 'moment';
import { defineComponent, reactive, toRefs, ref, onMounted, watch,  } from "vue";
import { ContentLoader } from 'vue-content-loader';
import store from "@/store/index";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import useAlert from "@/composables/Alert.ts";
import { hideModal } from '@/core/helpers/dom';
import { getDelet } from "@/services/Concessionaria";
import useEmitter from '@/composables/Emmiter'

export default {
  name: "deleteconcessionariaModal",
  props: {
    dadosconcessionaria: {
      type: Array,
      required: true,
    },
  },
  components:{
	  ContentLoader
  },
  setup(props) {
  let logs = ref({});
  let codConcessionaria = ref();
	let animate = ref(true);
  const emitter = useEmitter();
  const {  showTimeAlert } = useAlert();

    watch(() => props.dadosconcessionaria, () => {   
       logs.value = props.dadosconcessionaria
    });

    async function deletConcessionaria(codConcessionaria) {
      animate.value = true
      let deletar = await getDelet(codConcessionaria);
      animate.value = false
      location.reload(); 
    };

      function formatarData(databruta) {
      if (databruta) {                                                                                  
        return moment(String(databruta)).format('DD/MM/YYYY HH:mm')
      }
    }

    function deletarConcessionaria() {
      animate.value = true
      if (props.dadosconcessionaria) {
        Swal.fire({
          title: "Confirmação de alteração",
          text: "Essa alteração não poderá ser desfeita!",
          showCancelButton: true,
          confirmButtonText: "Excluir concessionária",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
             confirmButton: "btn btn-flex background_btn_1_opct text-white",
             cancelButton: "btn btn-light me-3",
          },
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
                deletConcessionaria(props.dadosconcessionaria?.codConcessionaria);   
                const elementModal = document.getElementById("kt_modallog_1");
                hideModal(elementModal);    
                showTimeAlert("Concessionária excluída com sucesso");
            } 
        });
          animate.value = false
      }
    }

    return {
      logs,
      animate,
      codConcessionaria,  
      deletarConcessionaria,
      formatarData
    };
  },
};
